@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.tve-view-curator {
  color: var(--white);
  background-color: var(--grey-70);
  width: 100vw !important;
  margin-bottom: h.rem(40px);

  @include h.breakpoint-m {
    margin-left: -40px;
  }

  @include h.breakpoint-l {
    margin-left: calc((880px - 100vw) / 2);
  }

  @include h.breakpoint-x {
    margin-left: calc((1120px - 100vw) / 2);
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }

  .videoPlayer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  // Layout
  &__inner {
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
  }

  &__player-outer,
  &__player-outer-ndp {
    position: relative;

    @include h.breakpoint-l {
      width: 60%;
      float: left;
    }
  }

  &__player-outer-ndp {
    aspect-ratio: 16 / 9;
  }

  &__info-outer {
    position: relative;

    @include h.breakpoint-l {
      width: 40%;
      float: right;
    }
  }

  &__schedule-outer {
    position: relative;
    padding: h.rem(20px 20px 30px 20px);

    @include h.breakpoint-m {
      padding: h.rem(20px 30px 24px 30px);
    }

    @include h.breakpoint-l {
      clear: both;
      padding: h.rem(24px 40px);
    }

    @include h.breakpoint-x {
      clear: none;
      float: right;
      width: 40%;
      padding: h.rem(0 40px 18px 30px);
    }
  }

  // Margin/Spacing
  &__info-inner {
    margin: h.rem(18px 20px);

    @include h.breakpoint-m {
      margin: h.rem(20px 30px);
    }

    @include h.breakpoint-l {
      margin: h.rem(26px 40px 18px 30px);
    }

    @include h.breakpoint-x {
      margin-right: h.rem(40px);
    }
  }

  // Maintain player proportions
  &__player-outer::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 56.25%;
    background: var(--black);
  }

  // TEMP: move sign in iframe out of the way
  &__player-outer > #mvpddiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
