@use 'assets/styles/utils/helper' as h;

.tease-visual {
  &__visual-wrapper {
    background-color: var(--grey-40);
    position: relative;

    &--M2 {
      width: 100%;
      flex-direction: column;
    }

    &--video-player {
      aspect-ratio: 16 / 9;
    }

    &--playmaker {
      background-color: var(--white);
      display: flex;
      flex-direction: column-reverse;

      & > div > section { // TODO: refactor this selector
        padding-bottom: 0;
      }

      .tve__cta {
        right: 0;

        &__authed-full {
          position: static;
          background-color: #222;
        }
      }
    }
  }

  &__video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    height: var(--space-32);
    width: auto;
    display: flex;
    align-items: center;
  }

  &__no-video-controls {
    display: none;
  }

  &__video-duration {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: var(--space-16);
    padding-right: var(--space-20);
    background-color: var(--white);
    color: var(--grey-60);
    font-family: var(--founders-mono);
    font-weight: 400;
    font-size: var(--text-12);
    line-height: var(--leading-100);
    text-align: center;
  }

  &__video-play {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--space-32);
    flex: 0 0 var(--space-32);
    background-color: var(--default-color);
    color: var(--white);
    font-size: h.rem(8px);
    line-height: var(--leading-100);
    text-align: center;

    @include h.breakpoint-s-only {
      width: var(--space-20);
    }
  }

  &__storyline {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: h.rem(-12px);
    left: var(--space-16);
    height: var(--space-24);
    padding: var(--space-8);
    background-color: var(--default-color);
    color: var(--white);
    font-family: var(--founders-mono);
    font-size: var(--text-12);
    line-height: var(--leading-100);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    vertical-align: center;
    // applied z-index to avoid video ad overlapping
    z-index: 999;
  }
}
