/* stylelint-disable no-descending-specificity */
// @TODO: sort the CSS in this module with nesting to solve specificity problem

@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';
@import '../sassVariables.module.scss';

:global(.#{$packageOuterClass}) {
  // Hide pip when it's back home in the container
  // but takes a moment to un-float.
  .liveVideoHomeContainer .liveVideoMovingContainer {
    :global(.sticky-pip.sticky-pip--stuck) {
      display: none;
    }
  }

  :global(.msp-pip-stuck) {
    &.liveVideoMovingContainer {
      height: 0;
      overflow: hidden;
      position: relative;
      z-index: 40; // on top of live video embed

      // ios specific styling
      @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
        overflow-anchor: none; // Disable scroll anchoring
        overflow: visible !important; // Ensure overflow is visible
        position: absolute;
        width: 0;
      }
    }
  }

  :global(.bottom) {
    bottom: 0;
  }

  :global(.top) {
    top: 0;
  }

  //// Badge Adjustments
  //
  // NBC News
  :global(.live-video-moving.hide-video-label .playmaker-tease__headline.storyline) {
    display: none;
  }

  :global(.playmaker-tease__headline.storyline),
  :global(.playmaker-tease-msnbc .playmaker-tease-msnbc__title) {
    font-size: 18px;
    margin-top: 0;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
    background-color: var(--grey-30);

    :global(.badge-label.playmaker-badge-label) {
      bottom: 1px;
    }

    :global(.badge-label.playmaker-badge-label.badge-type-LIVE),
    :global(.badge-label.playmaker-badge-label.badge-type-WATCH) {
      bottom: 4px;
    }
  }

  //
  //// End Badge Adjustments

  .multistory-media {
    .liveVideoHomeContainer {
      // border: solid green 1px;
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    &.multistory-small {
      :global(.multiStoryPicture) {
        width: var(--multistory-small-media-width, 147px);
        height: var(--multistory-small-media-height, 98px);

        img {
          object-fit: cover;
        }
      }
    }

    &.multistory-small.aspect-one-to-one {
      :global(.multiStoryPicture) {
        width: var(--multistory-small-media-width, 98px);
        height: var(--multistory-small-media-height, 98px);

        img {
          object-fit: cover;
          height: 98px;
        }
      }
    }

    @include h.breakpoint-s-only {
      &.multistory-small {
        :global(.multiStoryPicture) {
          width: 100%;
          height: 66%;
        }
      }

      &.multistory-small.aspect-one-to-one {
        :global(.multiStoryPicture) {
          width: 100%;
          height: 66%;
        }
      }
    }

    &.multistory-small-video {
      width: 100%;
    }
  }

  .storyline-media {
    display: block;
    position: relative;

    .teasePicture img {
      object-fit: cover;
      object-position: center;
    }
  }

  .storyline-media.image-ratio {
    @include h.breakpoint-s-only {
      position: relative;
      width: 100%;

      .teasePicture img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .storyline-media.multistory-standard-video {
    // width: 410px;

    .video-container,
    .videoPlayer {
      width: 400px;

      @include h.breakpoint-m {
        width: var(--media--large--video--m--width);
      }

      @include h.breakpoint-l {
        width: var(--media--large--video--l--width);
      }
    }
  }

  .storyline-media.liveblog-layoutMedia {
    .playmaker-tease {
      margin-bottom: var(--playmaker--tease--margin--bottom);

      @include h.breakpoint-m {
        margin-bottom: var(--playmaker--tease--margin--m--bottom);
      }
    }
  }

  .video-subheadline {
    text-align: left;
    font-size: 24px;
    font-family: var(--playmaker-tease-headline--font-family);
    font-weight: var(--playmaker-tease-headline--font-weight);
    line-height: var(--playmaker-tease-headline--line-height);
    margin: var(--playmaker-tease-headline--margin);
    margin-top: 8px;
    margin-bottom: 0;
    position: relative;

    .video-subline-link {
      cursor: text;

      a {
        position: relative;
        bottom: 3px;
      }

      .video-subline-badge {
        display: inline;
        position: relative;
        bottom: 3px;
      }
    }
  }

  .storyline-media.is-flex-item,
  .storyline-media.is-flex-item :global(.data-viz-div) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .storyline-media.is-flex-item :global(.data-viz-div) iframe {
    flex-grow: 1;
  }
}
