@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.cover-spread-tease {
  &__image-link {
    position: relative;
    display: block;
  }

  &__image {
    margin-bottom: var(--space-12);

    &.dn {
      margin-bottom: 0;
    }

    @include h.breakpoint-m {
      &.dn-m {
        margin-bottom: 0;
      }

      &.db-m {
        margin-bottom: var(--space-12);
      }
    }

    @include h.breakpoint-l {
      &.dn-l {
        margin-bottom: 0;
      }

      &.db-l {
        margin-bottom: var(--space-12);
      }
    }

    @include h.breakpoint-x {
      &.dn-xl {
        margin-bottom: 0;
      }

      &.db-xl {
        margin-bottom: var(--space-12);
      }
    }
  }

  &__unibrow {
    color: var(--cst--unibrow--color);
    font-size: var(--text-12);
    font-family: var(--founders-mono);
    font-weight: var(--light);
    text-transform: uppercase;
    line-height: var(--leading-100);
    margin: 0 0 var(--space-8);

    &--opinion {
      color: var(--cst--unibrow--opinion--color);
    }
  }

  &__headline {
    color: var(--grey-70);
    font-family: var(--founders-cond);
    line-height: var(--leading-100);
    margin: 0 0 var(--space-16);
    position: relative;

    .media-label__type-icon--inline {
      top: 7px;

      @include h.breakpoint-m-only {
        top: 4px;
      }

      @include h.breakpoint-x {
        top: 5.5px;
      }
    }

    .icon.icon-gallery.media-label__type-icon--inline {
      font-size: 9px;
    }

    &__flag {
      align-items: center;
      color: var(--live-flag--default--background-color);
      font-family: var(--publico-txt);
      font-size: var(--text-20);
      font-style: normal;
      font-weight: normal;
      position: relative;
      z-index: 2;

      &--live {
        color: var(--news-red-30);
      }
    }
  }

  &__headline-link {
    cursor: pointer;
  }
}

.cover-spread {
  &--default,
  &--live-blog {
    .cover-spread-tease--0 .playmaker-tease {
      margin-bottom: var(--space-12);

      @include h.breakpoint-m {
        margin-bottom: var(--space-16);
      }
    }
  }

  .cover-spread__column-two {
    .playmaker-tease {
      &__headline-link {
        font-family: var(--founders-cond);
        font-size: var(--text-24);
        line-height: var(--text-24);
        font-weight: 600;
      }

      &__flag {
        font-family: var(--publico-txt);
        font-size: var(--text-20);
        line-height: var(--text-20);
        font-weight: 400;
      }
    }
  }

  .cover-spread-featured {
    .cover-spread-tease {
      &__headline {
        @include h.breakpoint-x {
          padding-bottom: 0;
          margin-bottom: 0;
        }

        @include h.breakpoint-s-only {
          padding-left: 0;
        }
      }

      &__text-wrapper {
        @include h.breakpoint-s-only {
          margin: 0 var(--space-20);
          padding-bottom: var(--space-16);
        }
      }
    }
  }
}
