@use 'assets/styles/utils/helper' as h;

.container {
  width: 100%;
  max-width: h.rem(330px);
  margin: 0;

  @include h.breakpoint-m {
    margin-left: h.rem(15px);
    width: h.rem(330px);
  }

  @include h.breakpoint-l {
    width: h.rem(360px);
  }

  .gate {
    margin-top: 0;
  }
}

.overlay {
  position: relative;
  height: h.rem(70px);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

.letters {
  display: flex;
  flex-wrap: wrap;

  span {
    font-family: var(--secondary-font);
    font-size: h.rem(26px);
    line-height: 1.5;
    font-weight: var(--bold);
    text-align: center;
    width: 12.5%; //8 letters per row
    height: 12.5%;
    display: block;
  }
}
