@use 'assets/styles/utils/helper' as h;

.cover-spread-featured {
  @include h.breakpoint-l-only {
    .cover-spread--rail-adj & {
      margin-bottom: var(--space-12);
    }
  }

  &__headline {
    color: var(--grey-70);
    font-family: var(--founders-cond);
    font-size: var(--text-24);
    line-height: var(--leading-100);
    padding-bottom: var(--space-20);

    @include h.breakpoint-s-only {
      padding-left: var(--space-20);
      margin-bottom: 0;
    }

    @include h.breakpoint-m {
      font-size: var(--text-28);
      line-height: var(--text-28);
    }

    @include h.breakpoint-x {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__headline-link {
    cursor: pointer;
  }

  &__flag {
    align-items: center;
    color: var(--live-flag--default--background-color);
    font-family: var(--publico-txt);
    font-size: var(--text-20);
    font-style: normal;
    font-weight: normal;

    @include h.breakpoint-m {
      font-size: var(--text-24);
    }

    .cover-spread--rail-adj & {
      font-size: var(--text-20);

      @include h.breakpoint-m {
        font-size: var(--text-24);
      }
    }

    .cover-spread--breaking & {
      color: var(--news-red-30);
    }

    &--live {
      color: var(--news-red-30);
    }
  }

  &__flag-dot {
    background-color: var(--news-red-30);
    margin: 0 var(--space-4) var(--space-4) 0;
  }
}

.cover-spread-featured__embed {
  @include h.breakpoint-s-only {
    padding: var(--space-20) var(--space-16) 0;

    &--package-title {
      padding-top: 0;
    }
  }

  @include h.breakpoint-m {
    margin-bottom: var(--space-24);
  }

  @include h.breakpoint-l {
    margin-bottom: var(--space-20);
  }

  @include h.breakpoint-x {
    margin-bottom: 0;
  }

  .icon-arrow-link {
    display: inline-block;
    font-size: h.rem(15px);
    padding: 0 0 4px 10px;
    vertical-align: bottom;
  }

  .cover-spread-featured__headline {
    text-align: center;
    font-size: var(--text-24);
  }

  .cover-spread-featured__flag {
    font-size: var(--text-20);
  }
}

.playmaker-tease {
  .cover-spread-featured__headline {
    @include h.breakpoint-s-only {
      padding-left: 0;
      margin-inline: var(--outer-gutter);
    }
  }
}
