@use 'assets/styles/utils/helper' as h;

.visualWrapper {
  display: flex;
  position: relative;
  flex: 0 0 var(--space-20);
  flex-direction: column;
  height: var(--space-20);
  margin-top: h.rem(3px);
  margin-right: h.rem(10px);

  @include h.breakpoint-l {
    flex: 0 0 var(--space-80);
    height: var(--space-80);
    margin-top: 0;
  }

  &.image {
    display: flex;
  }

  &.showImageOnlyInWide {
    display: flex;

    @include h.breakpoint-m {
      display: none;
    }

    @include h.breakpoint-l {
      display: flex;
    }
  }

  &.compact {
    flex: 0 0 var(--space-20);
    height: var(--space-20);
  }

  @include h.breakpoint-s-only {
    height: auto;

    img {
      width: 98px;
      height: 98px;
    }
  }

  @include h.breakpoint-m {
    img {
      width: 80px;
      height: 80px;
    }
  }

  &.hideImageInNarrowZone {
    display: none;

    @include h.breakpoint-s-only {
      display: flex;
      height: auto;

      img {
        width: 98px;
        height: 98px;
      }
    }
  }

  &.showVideoOnlyInWide {
    .visual {
      @include h.breakpoint-m-only {
        display: none;
      }
    }
  }
}

.visualLink {
  display: block;
  width: 100%;
  height: 100%;
}

.videoControls {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  height: var(--space-20);
  width: 100%;
}

.videoDuration {
  display: none;

  @include h.breakpoint-x {
    align-items: center;
    background-color: var(--neutral-10);
    color: var(--grey-60);
    display: flex;
    flex: 1 1 var(--space-60);
    font-family: var(--founders-mono);
    font-size: var(--text-12);
    font-weight: 400;
    height: 100%;
    justify-content: center;
    letter-spacing: -0.04em;
    line-height: var(--leading-100);
    text-align: center;
  }
}

.videoPlay {
  align-items: center;
  background-color: var(--blue-40);
  color: var(--white);
  display: flex;
  flex: 0 0 var(--space-20);
  font-size: h.rem(8px);
  height: 100%;
  justify-content: center;
  line-height: var(--leading-100);
  text-align: center;
  width: var(--space-20);

  @include h.breakpoint-m {
    width: initial;
  }

  @include h.breakpoint-x {
    margin-top: 0;
  }
}
