@use 'assets/styles/utils/helper' as h;

.waffleHeader {
  --waffle-header--margin: 0;
  // Description
  --waffle-header--description--font-family: var(--default-text-font-family);
  --waffle-header--description--font-size: #{h.rem(16px)};
  --waffle-header--description--font-weight: normal;
  --waffle-header--description--margin: 8px 0 0;
  // Disclaimer
  --waffle-header--disclaimer--background: transparent;
  --waffle-header--disclaimer--color: var(--grey-40);
  --waffle-header--disclaimer--font-family: var(--publico-txt);
  --waffle-header--disclaimer--font-size: var(--text-12);
  --waffle-header--disclaimer--line-height: var(--space-20);
  --waffle-header--disclaimer--max-width: 100%;
  --waffle-header--disclaimer--margin: 16px auto 16px;
  --waffle-header--disclaimer--padding: 0;
  --disclaimer-leadin--font-family: var(--founders-cond);
  // Disclaimer link
  --waffle-header--disclaimer-link--color: var(--white);
  --waffle-header--disclaimer-link--text-decoration: underline;
  --waffle-header--disclaimer-link--text-decoration-color: var(--white);
  // Disclaimer separator
  --waffle-header--separator--display: block;
  // Filter results
  --waffle-header--results--margin: var(--space-12) 0 0 0;
  // Filter wrapper
  --waffle-header--filters--background-color: var(--default-neutral);
  --waffle-header--filters--margin: 20px auto;
  --waffle-header--filters-after-dek--margin: var(--waffle-header--filters--margin);
  --waffle-header--filters--open--background-color: var(--waffle-header--filters--background-color);
  // Filter tags
  --waffle-header--tag--color: var(--grey-70);
  --waffle-header--tag--border-color: var(----waffle-header--tag--color);
  --waffle-header--tag--hover-color: var(--waffle-header--tag--color);
  --waffle-header--tag--font-family: var(--default-headline-font-family);
  --waffle-header--tag--font-size: #{h.rem(20px)};
  --waffle-header--tag--font-weight: var(--semibold);
  --waffle-header--tag--line-height: var(--leading-100);
  // Mobile filter call to action
  --waffle-header--cta-button--background: var(--white);
  --waffle-header--cta-button--border-radius: #{h.rem(100px)};
  --waffle-header--cta-button--color: var(--default-color);
  --waffle-header--cta-name--color: var(--waffle-header--cta-button--color);
  --waffle-header--cta-button--font-family: var(--founders-cond);
  --waffle-header--cta-button--font-size: #{h.rem(18px)};
  --waffle-header--cta-button--font-weight: var(--semibold);
  --waffle-header--cta-button--text-transform: uppercase;
  // Overlay button wrapper
  --waffle-header--overlay-btn-wrapper--background-color: #f8f8f8;
  --waffle-header--overlay-btn-wrapper--font-family: var(--founders-cond);
  // Overlay buttons
  --waffle-header--overlay-btn--background-color: transparent;
  --waffle-header--overlay-btn--border-color: var(--default-color);
  --waffle-header--overlay-btn--border-radius: 0;
  --waffle-header--overlay-btn--border-stroke: 1px;
  --waffle-header--overlay-btn--font-weight: var(--light);
  --waffle-header--overlay-btn--selected--background-color: var(--default-color);
  --waffle-header--overlay-btn--primary-color: var(--white);
  --waffle-header--overlay-btn--secondary-color: var(--black);
  --waffle-header--overlay-btn--selected--border-color: var(--accent-orange);
  --waffle-header--overlay-btn--flex: 0 1 calc(50% - 10px);
  // dek
  --waffle-header--dek--color: var(--white);

  @include h.breakpoint-m {
    --waffle-header--disclaimer--max-width: 570px;
    --waffle-header--filters--margin: 32px auto 20px;
  }

  @include h.breakpoint-l {
    --waffle-header--results--margin: var(--space-16) 0 0 0;
    --waffle-header--filters--background-color: transparent;
    --waffle-header--filters--margin: 48px auto 0;
    --waffle-header--tag--color: var(--white);
    --waffle-header--disclaimer--margin: 32px auto 32px;
  }

  :global(body.news) & {
    --waffle-header--cta-button--color: var(--black);
    --waffle-header--overlay-btn--selected--background-color: var(--blue-40);
  }

  :global(body.better) & {
    --waffle-header--cta-button--color: var(--purple-70);
    --waffle-header--overlay-btn--selected--background-color: var(--teal-30);
  }

  :global(body.think) & {
    --waffle-header--cta-button--color: var(--grey-70);
    --waffle-header--cta-button--font-weight: var(--light);
    --waffle-header--cta-button--font-family: var(--publico-txt);
    --waffle-header--overlay-btn--selected--background-color: var(--red-40);
    --waffle-header--tag--font-size: var(--text-18);
    --waffle-header--tag--font-weight: var(--light);
    --waffle-header--tag--line-height: var(--leading-125);
  }

  :global(body.msnbc) & {
    --waffle-header--overlay-btn--selected--background-color: var(--blue-40);
  }

  :global(body.noticias) & {
    --waffle-header--cta-button--color: var(--blue-40);
    --waffle-header--cta-button--font-weight: var(--semibold);
    --waffle-header--overlay-btn--selected--background-color: var(--red-50);
    --waffle-header--filters--background-color: var(--white);
    --waffle-header--filters--open--background-color: var(--default-neutral);
  }

  :global(body.telemundo) & {
    --waffle-header--cta-button--color: var(--blue-60);
    --waffle-header--cta-button--font-weight: var(--semibold);
    --waffle-header--overlay-btn--selected--background-color: var(--red-50);
    --waffle-header--filters--background-color: var(--white);
    --waffle-header--filters--open--background-color: var(--default-neutral);
  }

  :global(body.select) & {
    // Disclaimer
    --waffle-header--disclaimer--background: var(--blue-30);
    --waffle-header--disclaimer--color: var(--black);
    --waffle-header--disclaimer--font-family: var(--lato);
    --waffle-header--disclaimer--font-size: #{h.rem(14px)};
    --waffle-header--disclaimer--margin: 16px auto 16px auto;
    --waffle-header--disclaimer--max-width: 100%;
    --waffle-header--disclaimer--line-height: calc(16 / 14);
    // Disclaimer link
    --waffle-header--disclaimer-link--color: var(--blue-60);
    --waffle-header--disclaimer-link--text-decoration: none;
    // Disclaimer separator
    --waffle-header--separator--display: none;
    --waffle-header--cta-button--color: var(--blue-60);
    --waffle-header--cta-button--font-weight: 700;
    --waffle-header--cta-button--font-size: #{h.rem(16px)};
    --waffle-header--cta-button--font-family: var(--lato);
    // Overlay button wrapper
    --waffle-header--overlay-btn-wrapper--font-family: var(--lato);
    --waffle-header--overlay-btn-wrapper--background-color: var(--white);
    // Overlay buttons
    --waffle-header--overlay-btn--background-color: var(--white);
    --waffle-header--overlay-btn--border-radius: 27px;
    --waffle-header--overlay-btn--border-stroke: 1px;
    --waffle-header--overlay-btn--font-weight: var(--bold);
    --waffle-header--overlay-btn--secondary-color: var(--blue-60);
    --waffle-header--overlay-btn--selected--border-color: var(--blue-60);
    --waffle-header--overlay-btn--selected--background-color: var(--blue-70);
    --waffle-header--overlay-done-btn-wrapper--background-color: var(--blue-60);
    --waffle-header--overlay-btn--flex: 0 1 calc(50% - 10px);
    --waffle-header--cta-button--width: #{h.rem(335px)};
    // Mobile filter menu
    --waffle-header--filters--open--background-color: var(--blue-60);
    // Clear all filter button
    --waffle-header--clear-all--decoration-color: var(--white);
    --waffle-header--clear-all-hover--color: var(--blue-60);
    --waffle-header--clear-all--color: var(--blue-60);
    --waffle-header--clear-all--decoration-thickness: 0;
    --waffle-header--clear-all--underline-offset: 4px;
    --waffle-header--clear-all--text-decoration: unset;
    --default-hover-opacity: 70%;

    @include h.breakpoint-m {
      --waffle-header--filters--margin: 24px auto 20px;
    }

    @include h.breakpoint-l {
      --waffle-header--disclaimer--margin: 16px auto 20px auto;
      --waffle-header--results--margin: var(--space-24) 0 0 0;
      --waffle-header--filters--margin: 36px auto 0;
      --waffle-header--filters--background-color: transparent;
      --waffle-header--filters-after-dek--margin: 26px auto 0;
      --waffle-header--tag--color: var(--grey-70);
    }
  }

  :global(body.eonline) & {
    --waffle-header--description--margin: 16px 0 0;

    @include h.breakpoint-l {
      --waffle-header--description--margin: 32px 0 0;
    }
  }

  :global(body.today) & {
    --waffle-header--margin: #{h.rem(0 0 36px)};
    --waffle-header--description--font-family: var(--secondary-font);
    --waffle-header--description--font-size: #{h.rem(16px)};
    --waffle-header--description--margin: #{h.rem(14px 0)};
    --waffle-header--dek--color: var(--grey-70);
    --waffle-header--disclaimer--font-family: var(--secondary-font);
    --waffle-header--disclaimer--font-size: #{h.rem(14px)};
    --waffle-header--disclaimer--color: var(--grey-70);
    --waffle-header--separator--display: none;
    --waffle-header--disclaimer-link--color: var(--grey-70);
    --waffle-header--disclaimer-link--text-decoration-color: var(--accent-orange);
    --waffle-header--tag--font-family: var(--secondary-font);
    --waffle-header--tag--line-height: var(--leading-125);
    --waffle-header--tag--font-weight: normal;
    --waffle-header--tag--border-color: var(--accent-orange);
    --waffle-header--tag--color: var(--grey-70);
    --waffle-header--tag--hover-color: var(--primary-color);
    --waffle-header--filters--margin: 24px auto 20px;
    --waffle-header--cta-name--color: var(--black);
    --waffle-header--cta-button--border-radius: none;
    --waffle-header--cta-button--font-family: var(--secondary-font);
    --waffle-header--cta-button--background: transparent;
    --waffle-header--cta-button--font-weight: normal;
    --waffle-header--cta-button--color: var(--default-color);
    --waffle-header--cta-button--width: #{h.rem(335px)};
    --waffle-header--overlay-btn-wrapper--font-family: var(--secondary-font);
    --waffle-header--overlay-btn--font-weight: normal;
    --waffle-header--overlay-btn--primary-color: var(--black);
    --waffle-header--overlay-btn--secondary-color: var(--black);
    --waffle-header--overlay-btn--selected--border-color: var(--default-color);
    --disclaimer-leadin--font-family: var(--secondary-font);
    --waffle-header--filters--background-color: var(--white);
    --waffle-header--overlay-btn-wrapper--background-color: var(--white);
    --waffle-header--tag--font-size: #{h.rem(16px)};
    --waffle-header--overlay-done-btn-wrapper--background-color: var(--accent-orange);
    --waffle-header--clear-all--text-decoration: underline;
    --waffle-header--clear-all--decoration-color: var(--accent-orange);
    --waffle-header--clear-all-hover--color: var(--default-color);
    --waffle-header--clear-all--color: var(--grey-70);
    --waffle-header--clear-all--decoration-thickness: 2px;
    --waffle-header--clear-all--underline-offset: 4px;
    --default-hover-opacity: 100%;

    @include h.breakpoint-m {
      --waffle-header--tag--font-size: #{h.rem(20px)};
      --waffle-header--description--margin: #{h.rem(16px 0)};
    }

    @include h.breakpoint-l {
      --waffle-header--margin: 0;
      --waffle-header--description--font-size: #{h.rem(24px)};
      --waffle-header--description--margin: #{h.rem(32px 0)};
    }
  }

  :global(body.select .product-waffle) & {
    --waffle-header--tag--font-size: #{h.rem(16px)};
    --waffle-header--tag--font-weight: 400;
    --waffle-header--description--color: var(--grey-70);
    --waffle-header--disclaimer--color: var(--grey-70);
    --waffle-header--disclaimer--font-size: #{h.rem(12px)};
    --waffle-header--filters--margin: 16px auto 20px;
  }

  :global(body.today .product-waffle) & {
    --waffle-header--filters--margin: 24px auto 20px;
  }
}
