@use 'assets/styles/utils/helper' as h;

$total-padding: h.rem(8px) * 4 !default;

.live-blog-pkg {
  // Border top
  --live-blog-pkg-border-top-color: var(--default-color);
  --live-blog-pkg-border-top-color-breaking: var(--news-red-30);

  // Headline
  --live-blog-pkg-headline-font-family: var(--founders-cond);
  --live-blog-pkg-headline-font-size: var(--text-24);
  --live-blog-pkg-headline-font-style: normal;
  --live-blog-pkg-headline-font-weight: normal;
  --live-blog-pkg-headline-line-height: 1;
  --live-blog-pkg-headline-letter-spacing: normal;
  --live-blog-pkg-headline-color: var(--black);

  // Headline >> Label
  --live-blog-pkg-label-font-family: var(--publico-txt);
  --live-blog-pkg-label-font-weight: normal;
  --live-blog-pkg-label-font-size: var(--text-20);
  --live-blog-pkg-label-line-height: #{h.rem(25px)};
  --live-blog-pkg-label-color: var(--default-color);
  --live-blog-pkg-label-color-breaking: var(--news-red-30);

  // Headline >> Label (Headline Text Hidden)
  --live-blog-pkg-label-font-family-headline-text-hidden: var(--founders-cond);
  --live-blog-pkg-label-font-size-headline-text-hidden: var(--text-18);
  --live-blog-pkg-label-line-height-headline-text-hidden: 1;

  &--chatStyle {
    --live-blog-pkg-label-color-breaking: var(--red-50);
    --live-blog-pkg-headline-font-size-hidden: var(--text-18);

    @include h.breakpoint-m {
      --live-blog-pkg-label-font-size-headline-text-hidden: var(--text-24);
    }
  }

  body.today & {
    // Headline
    --live-blog-pkg-headline-font-style: italic;
    --live-blog-pkg-headline-font-weight: var(--light);
    --live-blog-pkg-headline-line-height: var(--leading-125);
    --live-blog-pkg-headline-letter-spacing: #{h.rem(0.2px)}; // TODO add to design-tokens
    --live-blog-pkg-headline-color: var(--purple-70);

    // Headline >> Label
    --live-blog-pkg-label-font-family: var(--founders-cond);
    --live-blog-pkg-label-font-weight: var(--semibold);
    --live-blog-pkg-label-font-size: var(--text-28);
  }

  body.think & {
    // Headline
    --live-blog-pkg-headline-line-height: var(--leading-125);

    // Headline >> Label
    --live-blog-pkg-label-font-family: var(--founders-cond);
    --live-blog-pkg-label-font-weight: var(--semibold);
    --live-blog-pkg-label-font-size: var(--text-28);
  }

  body.telemundo & {
    --live-blog-pkg-label-color-breaking: var(--noticias-red-50);
  }

  body.noticias & {
    --live-blog-pkg-label-color-breaking: var(--noticias-red-50);
  }

  body.entretenimiento & {
    --live-blog-pkg-label-color-breaking: var(--noticias-red-50);
  }

  body.shows & {
    --live-blog-pkg-label-color-breaking: var(--noticias-red-50);
  }
}
