@use 'assets/styles/utils/helper' as h;

:root {
  --playmaker-tease--width: auto;
  --playmaker-tease-headline--font-family: var(--founders-cond);
  --playmaker-tease-headline--font-weight: bold;
  --playmaker-tease-headline--line-height: 1;
  --playmaker-tease-headline--margin: #{h.rem(16px 0)};
  --playmaker-tease-dot--background-color: #{h.$live-flag-active-background-color};
  --playmaker-tease-flag--color: #{h.$live-flag-active-background-color};
  --playmaker-tease-flag--font-family: var(--publico-txt);
  --playmaker-tease-flag--text-transform: none;
}

body.select {
  --playmaker-headline--font-family: var(--lato);
  --playmaker-headline--font-size--mobile: #{h.rem(14px)};
  --playmaker-headline--line-height--mobile: #{h.rem(14px)};
  --playmaker-headline-date--font-family: var(--lato);
}

body.today {
  --playmaker-headline--font-family: var(--publico-hed);
  --playmaker-headline--line-height: #{h.rem(36px)};
  --playmaker-headline--font-size--mobile: #{h.rem(16px)};
  --playmaker-headline--line-height--mobile: #{h.rem(24px)};
  --playmaker-tease-headline--font-family: var(--primary-font);
  --playmaker-tease-headline--font-weight: normal;
  --playmaker-tease-headline--line-height: 1.5;
  --playmaker-tease-headline--margin: 0;
  --playmaker-tease-dot--background-color: var(--breaking-red);
  --playmaker-tease-flag--color: var(--breaking-red);
  --playmaker-tease-flag--font-family: var(--primary-font);
  --playmaker-tease-flag--text-transform: uppercase;

  .straight-up {
    --playmaker-tease--padding: #{h.rem(16px 20px 0)};

    @include h.breakpoint-m {
      --playmaker-tease--padding: #{h.rem(20px 0 0)};
    }

    @include h.breakpoint-l {
      --playmaker-tease--width: #{h.rem(520px)};
      --playmaker-tease--padding: #{h.rem(0 0 0 40px)};
    }

    @include h.breakpoint-x {
      --playmaker-tease--width: #{h.rem(705px)};
    }
  }
}
