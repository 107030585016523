@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.media {
  margin-right: var(--related-content-tease-media-margin-right);
  width: var(--related-content-tease-media-width-mobile);
  height: var(--related-content-tease-media-height-mobile);
  display: var(--related-content-tease-media-display);
  justify-content: var(--related-content-tease-media-justify-content);
  align-items: var(--related-content-tease-media-align-items);
  flex-shrink: var(--related-content-tease-media-flex-shrink);
  overflow: var(--related-content-tease-media-overflow);
  position: var(--related-content-tease-media-position);

  @include h.breakpoint-m {
    width: var(--related-content-tease-media-width);
    height: var(--related-content-tease-media-height);
  }

  .picture {
    width: var(--related-content-tease-media-width);
    height: var(--related-content-tease-media-height);

    img {
      width: var(--related-content-tease-media-picture-img-width);
      height: var(--related-content-tease-media-picture-img-height);
    }
  }

  .icon {
    position: var(--related-content-tease-media-icon-position);
    bottom: var(--related-content-tease-media-icon-bottom);
    left: var(--related-content-tease-media-icon-left);
    height: var(--related-content-tease-media-icon-height);
    width: var(--related-content-tease-media-icon-width);
  }

  &.narrowRelated {
    margin-right: 0;
  }
}
