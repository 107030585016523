@use 'assets/styles/utils/helper' as h;

:root {
  --shop-button-vendor-border-color: var(--default-color);
  --shop-button-vendor-bg-color: var(--default-color);
  --shop-button-cost-border-color: var(--default-color);
  --shop-button-vendor-name-font: var(--founders-mono);
  --shop-button-cost-font: var(--founders-mono);
  --shop-button-cost-font-weight: 500;
  --shop-button-cost-letter-spacing: unset;
  --shop-button-cost-flex-direction: column;
  --shop-button-sale-cost-color: #fa3a3a;
  --shop-button-sale-cost-font-weight: bold;
  --shop-button-list-cost-color: var(--grey-50);
  --shop-button-info-shop-hover-font-weight: 600;
  --shop-button-info-shop-hover-transform: scaleY(0.99);
}

body.today {
  --shop-button-variation-3--list-price--color: var(--black);
  --shop-button-variation-3--sale-price--color: var(--black);
  --shop-button-variation-3--seller-text--color: var(--black);
  --shop-button-variation-3--background-color: var(--accent-orange);
  --shop-button-variation-3--background-color--hover: var(--primary-color);
}

body.today.articlePage {
  --shop-button-vendor-bg-color: transparent;
  --shop-button-vendor-name-font: var(--secondary-font);
  --shop-button-cost-font: var(--secondary-font);
  --shop-button-cost-flex-direction: row;
  --shop-button-list-cost-color: var(--white);
  --shop-button-sale-cost-font-weight: 500;
  --shop-button-info-shop-hover-font-weight: 700;
  --shop-button-info-shop-hover-transform: none;
  --shop-button-sale-cost-color: var(--white);
}

body.globalcitizen {
  --shop-button-vendor-border-color: var(--red-40);
  --shop-button-vendor-bg-color: var(--red-40);
  --shop-button-cost-border-color: var(--red-40);
}

body.select {
  --shop-button-vendor-name-font: var(--lato);
  --shop-button-cost-font: var(--lato);
  --shop-button-cost-letter-spacing: -0.5px;
  --shop-button-cost-font-weight: 700;
  --shop-button-vendor-bg-color: var(--blue-60);
  --shop-button-vendor-border-color: var(--blue-60);
  --shop-button-cost-border-color: var(--blue-60);
  --shop-button-list-cost-color: #999;
  --shop-button-variation-3--background-color: var(--blue-60);
  --shop-button-variation-3--background-color--hover: var(--blue-60);
  --shop-button-variation-3--list-price--color: var(--white);
  --shop-button-variation-3-hover--on-sale-price--color: var(--white);
  --shop-button-variation-3--sale-price--color: var(--white);
  --shop-button-variation-3--seller-text--color: var(--white);
  --shop-button-border-raidus-variation-3: 100px;
  --shop-button-variation-3--hover-opacity: .7;
}
