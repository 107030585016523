@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

// lbp = live-blog-pkg

.lbp-card {
  display: block;
  padding: var(--lbp-card--padding);
  position: relative;

  &:hover {
    opacity: var(--lbp-card-hover-opacity);
  }

  &:active {
    opacity: var(--default-active-opacity);
  }

  &--large {
    padding-bottom: var(--space-20);
  }

  &--storylineChatStyle {
    min-height: 40px;
  }

  &.lbp-card__link--storylineChatStyle {
    display: grid;
    grid-template-columns: 1fr 16px;
    align-items: center;
    gap: 0.5rem;

    .lbp-card.lbp-card--animate-in:first-child & {
      display: grid;
    }
  }

  &.lbp-card--animate-in:first-child {
    opacity: var(--opacity-0);
    animation: fade-in 1000ms ease-out forwards;

    .lbp-card__link {
      display: block;
      height: auto;
      max-height: 0;
      overflow-y: hidden;
      animation: expand-in 1000ms ease-out forwards;

      &--storylineChatStyle {
        animation: chatblog-expand-in 1000ms ease-out forwards;
      }
    }
  }

  &::before {
    content: '';
    display: block;
    height: 9px;
    width: 9px;
    background-color: var(--lbp-card-bullet-background-color);
    border-radius: 50%;
    position: absolute;
    left: -4px;
    top: var(--lbp-card-top-margin);
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: var(--space-1);
    position: absolute;
    left: 0;
    top: var(--space-12);
    background-image: var(--lbp-card-background-image);
    background-size: var(--lbp-card-background-size);
  }

  // Hide dashed line for last child
  &:last-child {
    &::after {
      display: none;
    }
  }

  body.today &:last-child {
    padding-bottom: 4px;
  }

  // change bullet color when breaking
  &--breaking::before {
    background-color: var(--lbp-card-bullet-background-color-breaking);
  }

  &__time {
    position: relative;
    height: var(--space-12);
    font-size: var(--text-12);
    color: var(--lbp-card-time-color-ago);
    font-family: var(--lbp-card-font-family);
    line-height: var(--leading-100);
    margin-bottom: 2px; // TODO: Update with variable once added to design system
    display: block;

    &--just-updated {
      color: var(--lbp-card-time-color-just-updated);

      .lbp-card--breaking & {
        color: var(--lbp-card-time-color-just-updated-breaking);
      }
    }

    &--show.lbp-card__time__text,
    &--show.lbp-card__time__just-now {
      animation: fade-in 300ms linear forwards;
    }

    &__text,
    &__just-now {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }

  &__pinned {
    height: var(--space-12);
    font-size: var(--text-12);
    color: var(--lbp-card-pinned-color);
    font-family: var(--founders-mono);
    line-height: var(--leading-100);
    margin-bottom: 2px; // TODO: Update with variable once added to design system
    display: block;

    &--breaking {
      color: var(--lbp-card-pinned-color-breaking);
    }
  }

  &__headline {
    font-size: var(--lbp-card-headline-font-size);
    font-family: var(--lbp-card-headline-font-family);
    font-weight: var(--lbp-card-headline-font-weight);
    line-height: var(--lbp-card-headline-line-height);
    color: var(--lbp-card-headline-color);
    margin: 0;
    text-decoration: var(--lbp-card-headline-text-decoration);
    text-decoration-color: var(--lbp-card-text-decoration-color);
    text-decoration-thickness: var(--lbp-card-text-decoration-thickness);
    text-underline-offset: var(--lbp-card-headline-text-underline-offset);

    &:hover {
      color: var(--lbp-card-headline-hover-text-color);
    }

    // Override M font-size when within Cover Spread package
    @include h.breakpoint-m-only {
      .live-blog--coverSpread & {
        font-size: var(--text-16);
      }
    }

    // Override font-size when within Storyline package
    .live-blog--Storyline &,
    .live-blog--MultiStoryline3 & {
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
    }
  }
}

.lbp-card--chatStyle {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 23px;
  margin-left: 10px;

  .lbp-card__meta-line--chatStyle,
  .lbp-card__headline--chatStyle {
    grid-area: main;
  }

  .lbp-card__headline--chatStyle {
    font-family: var(--publico-txt);
    font-size: var(--text-14);
    line-height: var(--leading-150);
    /* stylelint-disable-next-line value-no-vendor-prefix */
    -webkit-line-clamp: 3;
  }

  &::before {
    display: none;
  }

  &::after {
    background-image: linear-gradient(180deg, var(--grey-40) 0%, var(--grey-40) 100%);
    background-size: unset;
  }

  &:last-child {
    &::after {
      display: block;
      background-image: linear-gradient(180deg, var(--grey-40) 0%, transparent 80%);
    }
  }

  .icon {
    grid-area: chevron;
    color: var(--grey-60);
  }

  .lbp-card__meta-line--chatStyle {
    margin-bottom: var(--space-4);
    letter-spacing: -0.48px;

    .lbp-card__time,
    .lbp-card__pinned {
      margin-bottom: 0;
      display: inline-grid;
    }

    .lbp-card__time__text,
    .lbp-card__time__just-now {
      // overwrite position abolute for the animation, leverage grid to accomplish
      // same origin position for text & just-now. Content is now not out of the follow.
      // This allows "text" & "just-now" to be next to the authors name
      position: static;
      grid-column: 1;
      grid-row: 1;
    }
  }
}

.lbp-card--storylineChatStyle::before {
  display: none;
}

.lbp-card--storylineChatStyle::after {
  left: 14px;
}

.lbp-card--storylineChatStyle:last-child::after {
  display: block;
  background-image: linear-gradient(180deg, var(--grey-30), var(--grey-30), transparent 80%, transparent 100%);

  .live-blog-pkg--embedded & {
    display: none;
  }

  .live-blog-pkg__list.live-blog-pkg__list--rail & {
    display: block;
  }
}

.lbp-card__link--chatStyle {
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-template-areas: 'main chevron';
  align-items: center;
  gap: 0.5rem;

  .lbp-card.lbp-card--animate-in:first-child & {
    display: grid;
  }
}

.lbp-card__avatar--pkg {
  --width: 28px;
  position: absolute;
  width: var(--width);
  height: var(--width);
  left: calc(var(--width) / -2);
  top: 10px;
  z-index: 1;
  border: 2px solid var(--lbp-card-pkg-avatar-color);
  background-color: var(--lbp-card-pkg-avatar-color);
  border-radius: 100%;

  img {
    height: auto;
  }
}

.lbp-card__avatar--pkg.isStorylineChat {
  left: 0;
  top: 0;
  border: none;
}

.lbp-card__meta-line__bylines--chatStyle {
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  line-height: var(--leading-100);
}

.lbp-card__meta-line__bylines--storylineChatStyle {
  font-family: var(--founders-mono);
  font-size: var(--text-12);
  line-height: var(--leading-100);
  display: block;
}

.lbp-card__peacock-avatar {
  border-radius: 100%;
  background-color: var(--avatar-bg-color);
}

.lbp-card__time.isInline,
.lbp-card__pinned.isInline {
  margin-bottom: 0;
  display: inline-grid;
  white-space: nowrap;

  .lbp-card__time__text,
  .lbp-card__time__just-now {
    position: static;
    grid-column: 1;
    grid-row: 1;
  }
}

.lbp-card__trunc {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lbp-card__graf {
  -webkit-line-clamp: 2;
}
