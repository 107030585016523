@use 'assets/styles/utils/helper' as h;

.small-storyline.standard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .related {
    padding-left: 20px;
    border-left: 1px solid #ccc;
    min-height: 36px;

    @include h.breakpoint-m {
      min-height: 147px;
    }
  }

  .related-border {
    border: none;
    border-top: 1px solid var(--grey-40);
    margin: 0;

    @include h.breakpoint-m {
      border-top: 1px solid var(--grey-30);
    }
  }

  .related-item {
    color: #000;
    font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .related-content-tease:first-child {
    margin-top: 0;
  }

  .main {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 16px;

    &.no-relateds {
      border-right: none;
    }
    border-right: 1px solid #ccc;
  }

  .headline {
    .dek {
      color: #000;
      font-family: PublicoText, Georgia, TimesNewRoman, 'Times New Roman', Times, Baskerville, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 8px;
    }

    .author,
    .deliminator {
      color: #2a2a2a;
      font-family: FoundersGroteskMono, Courier, 'Courier New', monospace;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.48px;
      text-transform: uppercase;
    }

    .timestamp {
      color: #555;
      font-family: FoundersGroteskMono, Courier, 'Courier New', monospace;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.48px;
    }
  }

  @include h.breakpoint-s-only { // < 758pm
    flex-direction: column;
    padding: 0 20px;

    .headline {
      width: 100%;
    }

    .main {
      width: 100%;
      flex-direction: column-reverse;
      padding-right: 0;
      border-right: 0;
    }

    .related {
      border-left: none;
      width: 100%;
      padding: 0;
    }

    .related-content-tease:first-child {
      margin-top: 16px;
    }

    .related > div:last-child > .related-content-tease {
      // the div that wraps the related-content-tease has
      // no class so it's referred to here
      margin-bottom: 0;
    }
  }

  @include h.breakpoint-m-to-l-only { // 758px - 999px
    width: 701px;

    .related {
      width: 166px;
      height: 100%;
    }
  }

  @include h.breakpoint-l-only { // 1000px - 1239px
    width: 620px;

    .related {
      width: 145px;
      height: 100%;
    }
  }

  @include h.breakpoint-x { // >= 1240px
    .related {
      width: 207px;
      height: 100%;
    }
  }
}

.small-storyline.important {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
  }

  .related {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }

  .related-no-media {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .related-border {
    border: none;
    border-top: 1px solid var(--grey-30);
    margin: 0;
  }

  .related-item {
    color: #000;
    font-family: FoundersGroteskCond, 'Arial Narrow', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    margin: 0;
  }

  .related-content-tease:first-child {
    margin-top: 0;
  }

  .storyline-media {
    padding-right: 20px;
    border-right: 1px solid #ccc;
    margin-bottom: 0;
  }

  .headline-center {
    text-align: center;
  }

  .headline {
    .dek {
      color: #000;
      font-family: PublicoText, Georgia, TimesNewRoman, 'Times New Roman', Times, Baskerville, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-bottom: 8px;
    }

    .important-dek-no-author {
      margin-bottom: 0;
    }

    .author,
    .deliminator {
      color: #2a2a2a;
      font-family: FoundersGroteskMono, Courier, 'Courier New', monospace;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
      letter-spacing: -0.48px;
      text-transform: uppercase;
    }

    .timestamp {
      color: #555;
      font-family: FoundersGroteskMono, Courier, 'Courier New', monospace;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.48px;
    }
  }

  @include h.breakpoint-s-only { // < 758pm
    padding: 0 20px;

    .main {
      flex-direction: column;
    }

    .related {
      padding-left: 0;
    }

    .related-content-tease:first-child {
      margin-top: 16px;
    }

    .related > div:last-child > .related-content-tease {
      // the div that wraps the related-content-tease has
      // no class so it's referred to here
      margin-bottom: 0;
    }
  }

  @include h.breakpoint-m-to-l-only { // 758px - 999px
    width: 702px;
  }

  @include h.breakpoint-l-only { // 1000px - 1239px
    width: 619px;
  }
}
