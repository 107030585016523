@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

// This package has a class of "live-blog" as CardList already uses "live-blog-pkg".
// This could be refactored to combine LiveBlog and CardList, as the shared components they
// are responsible for rendering are always used (i.e. title, button,)
.live-blog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // required for IE

  &--standalone {
    padding: 0 var(--space-20);

    @include h.breakpoint-m {
      padding: 0;
    }
  }

  &__top-decoration {
    display: none;
    border-top: 1px solid var(--live-blog-package-top-decoration-border-color);
    margin: 0 var(--space-20); // migrated from outer-gutter

    // Enable top decoration in rail only
    .live-blog--in-rail & {
      display: block;
    }

    @include h.breakpoint-m {
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: var(--live-blog-package-top-decoration-tab-width);
      height: var(--live-blog-package-top-decoration-tab-height);
      background-color: var(--live-blog-package-top-decoration-tab-color);
      top: var(--live-blog-package-top-decoration-tab-top);
      left: var(--space-20);

      @include h.breakpoint-m {
        left: 0;
      }
    }
  }

  &__content-wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__content {
    position: relative;
    flex-grow: 1; // grow to fill extra space so button is at the bottom
  }

  &__button-wrapper {
    padding-top: var(--live-blog-package-button-wrapper-padding-top);

    .live-blog--in-rail & {
      display: flex;
      justify-content: var(--live-blog-package-button-justify-content);
    }
  }

  & &__button {
    color: var(--live-blog-package-button-color);
    border: 1px solid var(--live-blog-package-button-border-color);
    width: 100%;

    @include h.breakpoint-m {
      width: var(--space-192);
    }

    @include h.breakpoint-l {
      width: var(--space-120);
    }
  }

  &--in-rail &__button {
    @include h.breakpoint-m {
      margin: 0 var(--space-20);
      width: var(--space-192);
    }

    @include h.breakpoint-l {
      width: 100%;
    }
  }

  & &__button:hover {
    color: var(--button-text-hover-color);
    border-color: var(--button-border-hover-color);
  }
}

.live-blog--Storyline,
.live-blog--MultiStoryline3 {
  @include h.breakpoint-m {
    margin-top: 0;
  }

  &.has-headline {
    margin-top: 12px;

    @include h.breakpoint-m {
      margin-top: -4px;
    }
  }

  &.is-live {
    margin-top: 5px;

    @include h.breakpoint-m {
      margin-top: -8px;
    }

    @include h.breakpoint-x {
      margin-top: -4px;
    }
  }

  &.is-live.has-headline {
    margin-top: 8px;
  }
}

.live-blog--MultiStoryline3.has-headline {
  margin-top: 0;
}

.live-blog--Storyline.storylineChatStyle,
.live-blog--MultiStoryline3.storylineChatStyle {
  @include h.breakpoint-m {
    margin-top: 8px;
  }
}
