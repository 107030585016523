@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.wide-tease-item {
  &__wrapper {
    padding: var(--wide-tease-item-padding);

    &:not(.wide-tease-item__wrapper--no-border) {
      border-top: var(--wide-tease-item-border);
    }

    &:last-of-type:not(.wide-tease-item__wrapper--last-page) {
      height: var(--wide-tease-last-item-height);
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: h.rem(80px);
        background-image:
          linear-gradient(
            to bottom,
            rgba(var(--wide-tease-last-background-gradient-rgb), 0),
            var(--wide-tease-last-background-gradient)
          );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  &__unibrow-separator {
    padding: 0 6px;
    font-size: 12px;
    font-family: var(--wide-tease-item-timestamp-font-family);
    color: var(--wide-tease-item-unibrow-color);
  }

  &__unibrow-timestamp {
    margin: var(--wide-tease-item-unibrow-timestamp-margin);
    width: var(--wide-tease-item-unibrow-timestamp-width);

    .wide-tease-item__timestamp {
      display: var(--wide-tease-item-timestamp-display);
      margin-left: var(--wide-tease-item-timestamp-margin-left);
      height: var(--space-12);
      font-size: var(--wide-tease-item-timestamp-font-size);
      color: var(--wide-tease-item-timestamp-color);
      font-family: var(--wide-tease-item-timestamp-font-family);
      font-weight: var(--wide-tease-item-timestamp-font-weight);
      letter-spacing: var(--wide-tease-item-timestamp-letter-spacing);
    }
  }

  &__unibrow {
    margin: var(--wide-tease-item-unibrow-margin);
    height: var(--space-12);
    font-size: var(--wide-tease-item-unibrow-font-size);
    color: var(--wide-tease-item-unibrow-color);
    font-family: var(--wide-tease-item-unibrow-font-family);
    font-weight: var(--wide-tease-item-unibrow-font-weight);
    letter-spacing: var(--wide-tease-item-unibrow-letter-spacing);
    text-transform: var(--wide-tease-item-unibrow-text-transform);
    transition: opacity 0.25s ease-in-out;
    line-height: var(--wide-tease-item-unibrow-line-height);

    &:hover {
      opacity: 0.7;
    }

    &.think-module {
      color: var(--think-red-40);
    }
  }

  &__info-wrapper {
    &--no-art {
      padding-right: var(--wide-tease-no-art-padding);
    }
  }

  &__headline {
    font-size: var(--wide-tease-item-headline-font-size);
    line-height: var(--wide-tease-item-headline-line-height);
    color: var(--wide-tease-item-headline-color);
    font-family: var(--wide-tease-item-headline-font-family);
    font-weight: var(--wide-tease-item-headline-font-weight);
    margin: var(--wide-tease-item-headline-margin);
    transition: opacity 0.25s ease-in-out;

    &:hover {
      opacity: var(--wide-tease-item-headline-hover-opacity);
      text-decoration: var(--wide-tease-item-headline-hover-text-decoration);
      text-decoration-color:
        var(
          --wide-tease-item-headline-hover-text-decoration-color
        );
      text-underline-offset: 4px;
    }
  }

  &__description {
    flex-basis: var(--wide-tease-item-description-flex-basis);
    flex-grow: 1;
    font-family: var(--wide-tease-item-description-font-family);
    font-size: var(--wide-tease-item-description-font-size);
    color: var(--wide-tease-item-description-color);
    line-height: var(--wide-tease-item-description-line-height);
  }

  &__image-wrapper {
    margin: var(--wide-tease-item-image-margin);
    width: var(--wide-tease-item-image-width);

    a {
      display: block;
      position: relative;

      picture:hover {
        transform: var(--wide-tease-item-image-hover-transform);
      }
    }
  }

  &__no-image-save {
    margin: var(--wide-tease-item-image-margin);

    @include h.breakpoint-m {
      align-self: center;
    }
  }

  &__type-icon {
    position: relative;
    bottom: h.rem(30px);

    @include h.breakpoint-x {
      bottom: h.rem(32px);
    }
  }

  &__wrapper:has(.wide-tease-item__image-wrapper
> a picture:hover, .wide-tease-item__headline:hover) {
    > .wide-tease-item__image-wrapper > a picture {
      transform: var(--wide-tease-item-image-hover-transform);
    }

    .wide-tease-item__headline {
      text-decoration: var(--wide-tease-item-headline-hover-text-decoration);
      text-decoration-color:
        var(
          --wide-tease-item-headline-hover-text-decoration-color
        );
      text-underline-offset: 4px;
    }
  }

  &__wrapper--full-width {
    .wide-tease-item__info-wrapper {
      order: var(--wide-tease-item-info-order);
    }

    .wide-tease-item__save {
      left: -3%;
    }
  }
}
