@use 'assets/styles/utils/helper' as h;

$card-padding: h.rem(16px) !default;
$vertical-card-padding: $card-padding * 0.5 !default;

.lbp-card {
  --avatar-bg-color: var(--blue-70);
  --lbp-card-hover-opacity: var(--default-hover-opacity);
  // lbp = live-blog-pkg
  --lbp-card-bullet-background-color: var(--default-color);
  --lbp-card-bullet-background-color-breaking: var(--news-red-30);
  --lbp-card--padding: #{$vertical-card-padding} 0 #{$vertical-card-padding} #{$card-padding};
  --lbp-card-background-image: linear-gradient(180deg, var(--grey-40), var(--grey-40) 60%, transparent 60%, transparent 100%);
  --lbp-card-top-margin: var(--space-12);
  --lbp-card-background-size: var(--space-1) 5px;

  // Timestamp
  --lbp-card-time-color-ago: var(--black);
  --lbp-card-time-color-just-updated: var(--default-color);
  --lbp-card-time-color-just-updated-breaking: var(--news-red-30);
  --lbp-card-font-family: var(--founders-mono);

  // Pinned Text
  --lbp-card-pinned-color: var(--default-color);
  --lbp-card-pinned-color-breaking: var(--news-red-30);

  // Headline
  --lbp-card-headline-font-family: var(--default-headline-font-family);
  --lbp-card-headline-font-size: var(--text-18);
  --lbp-card-headline-font-weight: normal;
  --lbp-card-headline-color: var(--default-headline-color);
  --lbp-card-headline-line-height: var(--leading-100);
  --lbp-card-pkg-avatar-color: var(--grey-40);

  &--chatStyle {
    --lbp-card-time-color-ago: var(--red-50);
    --lbp-card-time-color-just-updated: var(--red-50);
    --lbp-card-time-color-just-updated-breaking: var(--red-50);
    --lbp-card-pinned-color: var(--default-color);
    --lbp-card-pinned-color-breaking: var(--red-50);
  }

  &--storylineChatStyle {
    --lbp-card-background-image: linear-gradient(180deg, var(--grey-30), var(--grey-30));
    --lbp-card-background-size: auto auto;
    --lbp-card-pkg-avatar-color: var(--blue-70);
  }

  .live-blog--Storyline &,
  .live-blog--MultiStoryline3 & {
    --lbp-card--padding: 5px 0 5px #{$card-padding};
  }

  .live-blog--Storyline &--storylineChatStyle,
  .live-blog--MultiStoryline3 &--storylineChatStyle {
    --lbp-card--padding: 5px 0 8px 36px;

    @include h.breakpoint-m {
      .liveBlogEmbedPage & {
        --lbp-card--padding: 5px 16px 8px 36px;
      }
    }
  }

  @include h.breakpoint-l {
    --lbp-card-headline-font-size: var(--text-16);
  }

  body.today & {
    // Headlines
    --lbp-card-headline-font-size: #{h.rem(20px)};
    --lbp-card-headline-font-weight: var(--normal);
    --lbp-card-headline-line-height: var(--leading-125);
    --lbp-card-headline-text-decoration: underline;
    --lbp-card--padding: #{h.rem(0 0 24px 20px)};
    --lbp-card-text-decoration: underline;
    --lbp-card-text-decoration-color: var(--accent-orange);
    --lbp-card-text-decoration-thickness: 2px;
    --lbp-card-bullet-background-color: var(--accent-blue);
    --lbp-card-bullet-background-color-breaking: var(--accent-blue);
    --lbp-card-headline-font-family: var(--tertiary-font);
    --lbp-card-background-image: none;
    --lbp-card-font-family: var(--secondary-font);
    --lbp-card-headline-color: var(--grey-70);
    --lbp-card-headline-hover-text-color: var(--primary-color);
    --lbp-card-hover-opacity: 1;
    --lbp-card-headline-text-underline-offset: 4px;
    --lbp-card-top-margin: 0;

    @include h.breakpoint-l {
      --lbp-card--padding: #{h.rem(0 0 36px 20px)};
    }
  }

  body.think & {
    // Headlines
    --lbp-card-headline-font-size: var(--text-14);
    --lbp-card-headline-font-weight: var(--light);
    --lbp-card-headline-line-height: var(--leading-125);

    @include h.breakpoint-m {
      --lbp-card-headline-font-size: var(--text-16);
    }

    @include h.breakpoint-l {
      --lbp-card-headline-font-size: var(--text-14);
    }
  }

  body.telemundo & {
    --lbp-card-bullet-background-color-breaking: var(--noticias-red-50);
  }

  body.noticias & {
    --lbp-card-bullet-background-color-breaking: var(--noticias-red-50);
  }

  body.entretenimiento & {
    --lbp-card-bullet-background-color-breaking: var(--noticias-red-50);
  }

  body.shows & {
    --lbp-card-bullet-background-color-breaking: var(--noticias-red-50);
  }

  body.msnbc & {
    --avatar-bg-color: var(--blue-40);

    &--storylineChatStyle {
      --lbp-card-pkg-avatar-color: var(--avatar-bg-color);
    }
  }
}
